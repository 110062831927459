<!-- 
 * @page: 费用明细详情
 * @Author: 张阳帅
 * @Date: 2024-10-09 17:52:37
 * @LastEditTime: 2024-10-09 17:52:37
 * @LastEditors: Please set LastEditors
-->

<template>
  <div class="pd_page">
    <div class="page_top">
      <div class="top_type">
        <div class="one_type">
          {{ detail.ddlxmc }}收费
        </div>
        <div class="one_type">
          付款日期:{{ dateSubstring(detail.fksj) }}
        </div>
      </div>
      <div class="top_price">
        {{ detail.zjeY }}元
      </div>
    </div> 
    <div class="page_one">
      交款人 {{ detail.fkr }} {{ detail.fklxmc }}支付
    </div>
    <div v-if="detail.detailItemList" class="page_item">
      <div class="item_title">
        项目名称
      </div>
      <div v-for="(item,index) in detail.detailItemList" :key="index" class="item_cont">
        <div class="cont_list">
          <div class="list_left">
            {{ item.xmmc }}
          </div>
          <div class="list_right">
            {{ item.sl }}次
          </div>
          <div class="list_price">
            {{ item.jghjY }}元
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gjcModel } from '../../../api/gjcApi'

export default {
    name:'PrescriptionDetail',
    data(){
        return{
            detail:{}
        }
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        getDetail(){
            let params={
                id:this.$route.query.id
            }
            gjcModel.getOrderInfoDetailData(params).then(res=>{
                this.detail = res.data
            })
        },
        dateSubstring(dateStr){
        let time = dateStr
        if(dateStr){
          time = time.substring(0,10)
        }
        return time
      }
    }
}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";
    .pd_page{
        background-color: white;
        padding: 0 15px;
        .page_top{
            margin: 20px 20px;
            padding: 10px 10px;
            display: flex;
            justify-content: space-between;
            justify-items: center;
            border: 2px solid #e1e1e1;
            font-size: 32px;
            color: #333;
            .top_price{
                margin-top: 25px;
            }
        }
        .page_one{
            padding: 10px 20px;
            font-size: 32px;
            color: #333;
            border-bottom: #aaa solid 1px;
        }
        .page_item{
            margin: 20px;
            .item_title{
                font-size: 32px;
                color: #333;
                font-weight: bold;
            }
            .item_cont{
                margin-top: 20px;
                .cont_list{
                    display: flex;
                    justify-content: space-between;
                    justify-items: center;
                    padding: 10px 0;
                    font-size: 32px;
                    color: #333;
                    .list_left{
                        padding-left: 15px;
                        width: 50%;
                    }
                    .list_right{
                        width: 20%;
                        text-align: center;
                    }
                    .list_price{
                        padding-right: 15px;
                        width: 30%;
                        text-align: right;
                    }
                }
            }
        }
    }
</style>
